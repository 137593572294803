"use strict";

class SSUserService {

    constructor($rootScope, $window, $parse, $timeout, $cookies, SSUtilService, SSHttpService, SSConfirmationService, SSAlertService, SSFirebaseMessagingService) {
        const self = this;

        self.$rootScope = $rootScope;
        self.$window = $window;
        self.$parse = $parse;
        self.$timeout = $timeout;
        self.$cookies = $cookies;

        self.SSHttpService = SSHttpService;
        self.SSUtilService = SSUtilService;
        self.SSConfirmationService = SSConfirmationService;
        self.SSAlertService = SSAlertService;
        self.SSFirebaseMessagingService = SSFirebaseMessagingService;

        self.TOKEN_SENT_SUCCESSFULLY = 1;
        self.TOKEN_WAS_ALREADY_SENT = 2;
        self.TOKEN_SENT_ERROR = 3;
        self.TOKEN_MISSING_REQUIRED_PARAMS = 4;

        self.TYPE_ENABLE_2FA = 1;
        self.TYPE_LOGIN = 2;
        self.TYPE_BOOKNOW = 3;
        self.TYPE_SESSION_2FA = 4;

        self.userStorageObject = SSUtilService.defineStorageDefault('def_user_service');

        self.user = SHOWSUITE.user;
        self.property = null;
        self.unit = null;
        self.enquireModal = false;
        self.registerFromEnquiry = false;

        self.registerParams = {};

        self.permissions = {};
        self.propertyLevelPermissions = {};

        self.forgetPasswordParams = {};
        self.forgetPasswordErrors = {};

        self.init();
    }

    init() {
        const self = this;

        self.$rootScope.$on('UserLoginRequiredEvent', function () {
            self.clearUser();
            self.showLoginModal();
        });
    }

    // User login

    loginUser(email, password, remember_me, callback) {
        const self = this;

        if (!(email && password)) {
            return;
        }

        let header = {
            email: email,
            password: password
        };

        let params = {
            remember_me: remember_me
        };

        loading(true);
        self.SSHttpService.getAPIRequest('auth', params, header).then(function (response) {
            loading(false);
            if (!(response instanceof Error) && response.data && response.data.user) {
                self.setUser(response.data.user);
                self.getProfile(function () {
                    if (callback) {
                        callback(response);
                    }
                });
            } else if (response.hasOwnProperty('data') && response.data.hasOwnProperty('forget_password_token')) {
                self.forget_password_token = response.data.forget_password_token;
                self.SSAlertService.danger("Your password has expired.", "Please reset your password.")
                self.showResetPasswordModal();
            } else {
                if (callback) {
                    callback(response);
                }
            }
        });
    }

    logoutUser() {
        const self = this;

        loading(true);
        self.SSHttpService.getAPIRequest('logout').then(
            function (response) {
                loading(false);

                self.clearUser();

                // Cancel 'userSession' API call
                if (self.timeout_promise) {
                    self.$timeout.cancel(self.timeout_promise);
                }

                self.$window.location = '/';
            }
        );
    }

    pingUserSession(){
        const self = this;

        self.SSHttpService.getAPIRequest('sec/ping').then(function (response) {
            self.timeout_promise = self.$timeout(function() { 
                self.pingUserSession(); 
            }, 60*1000);
            if(response instanceof Error){
                console.log("Something went wrong in sec/ping API call.");
                if (response.code == 401 && response.sub_code == 401001) {
                    self.SSAlertService.danger("Your session has expired.", "Please login again.");
                    self.clearUser();
                    self.showLoginModal();
                }
                self.$timeout.cancel(self.timeout_promise);
            }
        });
    }

    //-----------------------

    setUser(user) {
        const self = this;

        self.user = user;

        self.SSUtilService.saveToLocalStorage('user', user);
        self.$cookies.put('api_token', user.api_token, {path: '/'}); // Set api token for base path so it is available everywhere
        self.SSFirebaseMessagingService.setFirebaseToken(self.FIREBASE_VAPID_KEY);
    }

    clearUser() {
        const self = this;

        self.SSUtilService.deleteFromLocalStorage('user');
        self.$cookies.remove('api_token');
        self.$cookies.remove('skip_password');

        self.clearUserRoles();
        self.clearUserPermissions();
        self.clearRolePermissions();
    }

    getUser() {
        const self = this;

        return self.SSUtilService.getFromLocalStorage('user');
    }

    //-----------------------

    setUserRoles(user_roles) {
        const self = this;

        self.user_roles = user_roles;
        self.SSUtilService.saveToLocalStorage('user_roles', user_roles);
    }

    clearUserRoles() {
        const self = this;

        self.SSUtilService.deleteFromLocalStorage('user_roles');
    }

    getUserRoles() {
        const self = this;

        return self.SSUtilService.getFromLocalStorage('user_roles');
    }

    hasRole(name) {
        const self = this;

        let _hasRole = false;

        let userRoles = self.getUserRoles();
        if (userRoles) {
            jQuery.each(userRoles, function (i, userRole) {
                if (userRole.name == name) {
                    _hasRole = true;
                    return false;
                }
            });
        }

        return _hasRole;
    }

    //-----------------------

    setUserPermissions(user_permissions) {
        const self = this;

        self.user_permissions = user_permissions;
        self.SSUtilService.saveToLocalStorage('user_permissions', user_permissions);
    }

    clearUserPermissions() {
        const self = this;

        self.SSUtilService.deleteFromLocalStorage('user_permissions');
    }

    getUserPermissions() {
        const self = this;

        return self.SSUtilService.getFromLocalStorage('user_permissions');
    }

    //-----------------------

    setPermissions(permissions) {
        const self = this;

        self.SSUtilService.saveToLocalStorage('permissions', permissions);
    }

    clearPermissions() {
        const self = this;

        self.SSUtilService.deleteFromLocalStorage('permissions');
    }

    getPermissions() {
        const self = this;

        return self.SSUtilService.getFromLocalStorage('permissions');
    }

    //-----------------------

    setPropertyLevelPermissions(permissions) {
        const self = this;

        self.SSUtilService.saveToLocalStorage('property_level_permissions', permissions);
    }

    clearPropertyLevelPermissions() {
        const self = this;

        self.SSUtilService.deleteFromLocalStorage('property_level_permissions');
    }

    getPropertyLevelPermissions() {
        const self = this;

        return self.SSUtilService.getFromLocalStorage('property_level_permissions');
    }

    //-----------------------

    setRolePermissions(role_permissions) {
        const self = this;

        self.role_permissions = role_permissions;
        self.SSUtilService.saveToLocalStorage('role_permissions', role_permissions);
    }

    clearRolePermissions() {
        const self = this;

        self.SSUtilService.deleteFromLocalStorage('role_permissions');
    }

    getRolePermissions() {
        const self = this;

        return self.SSUtilService.getFromLocalStorage('role_permissions');
    }

    //--------------------------

    initPermissions(data){
        const self = this;

        let roles = data.roles;
        let userPermissions = data.permissions;
        let rolePermissions = data.role_permissions;

        if(userPermissions && rolePermissions){

            $.each(rolePermissions, function(index, permission){

                let _permission = self.getPermissionById(userPermissions, permission.permission_id);

                if(!permission.property_ids || permission.property_ids == ''){

                    if(_permission && _permission != undefined){
                        _permission.role_id = permission.role_id;
                        self.permissions[_permission.name] = true;
                    }
                }
                else if(permission.property_ids){

                    if(_permission && _permission != undefined){
                        _permission.property_ids = permission.property_ids;
                        _permission.role_id = permission.role_id;
                        self.propertyLevelPermissions[_permission.name] = _permission;
                    }
                }
            });

        }

        self.setPermissions(self.permissions);
        self.setPropertyLevelPermissions(self.propertyLevelPermissions);
    }

    getPermissionById(permissions, permission_id){
        const self = this;

        let permission = null;

        $.each(permissions, function(i, _permission){
            if(_permission.id == permission_id){
                permission = _permission;
                return false;
            }

        });

        return permission;
    }

    updateRolePermissions(){
        const self = this;

        let url = 'sec/me';
        let params = {
            include: 'role_permissions, roles, permissions'
        };

        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            // Update 'role_permissions' in local storage
            self.clearRolePermissions();
            self.setRolePermissions(response.data.role_permissions);

            // Refresh user permissions data
            self.permissions = {};
            self.propertyLevelPermissions = {};
            self.initPermissions(response.data);
        });
    }

    //-----------------------

    hasPermission(name) {
        const self = this;

        let _hasPermission = false;
        let user = self.getUser();

        if (user
            && user.role === 'SUPER'
        ) {
            _hasPermission = true;
        } else {
            if ($.isEmptyObject(self.permissions)) {
                self.permissions = self.getPermissions();
            }
            _hasPermission = self.permissions[name];
        }

        return _hasPermission;
    }

    hasPermissionForAnyProperty(name) {
        const self = this;

        if($.isEmptyObject(self.propertyLevelPermissions)){
            self.propertyLevelPermissions = self.getPropertyLevelPermissions();
        }

        return self.propertyLevelPermissions[name]? true : false;
    }

    hasPermissionForProperty(name, property_id) {
        const self = this;

        let _hasPermission = false;

        if(!name || !property_id){
            return false;
        }

        let id = ($.type(property_id) == "number") ? property_id.toString() : property_id;

        if($.isEmptyObject(self.propertyLevelPermissions)){
            self.propertyLevelPermissions = self.getPropertyLevelPermissions();
        }

        let permission = self.propertyLevelPermissions[name];

        if(permission && permission.property_ids){
            _hasPermission = $.inArray(id, permission.property_ids.split(",")) > -1? true : false;
        }

        return _hasPermission;
    }

    //-----------------------

    getProfile(callback) {
        const self = this;

        let user = self.getUser();
        if (!user) return;

        let url = 'sec/me?api_token=' + user.api_token + '&include=roles,permissions,role_permissions,agency';

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);
            if (!(response instanceof Error) && response.data && response.data.user) {

                /** WEB-2776 DMS: Phase Availability Settings - Developer Team Only
                 * Retrieve and set is_developer_team flag if broker user and broker agency has flag set **/
                let broker_agency = response.data.agency;
                response.data.user["is_developer_team"] = broker_agency ? broker_agency.is_developer_team : 0; // Init with 0 if flag is not set

                response.data.user.api_token = user.api_token;
                self.setUser(response.data.user);

                self.setUserRoles(response.data.roles);
                self.setUserPermissions(response.data.permissions);
                self.setRolePermissions(response.data.role_permissions);

                self.initPermissions(response.data);
            }

            if (callback) {
                callback(response);
            }
        });
    }

    getDisplayName(user) {
        const self = this;

        let displayName = '';
        if (!user) {
            user = self.getUser();
        }

        if (user) {
            if (user.display_name) {
                displayName = user.display_name;
            } else if (user.first_name || user.last_name) {
                displayName = user.first_name + ' ' + user.last_name;
            }
        }

        return displayName;
    }

    getDisplayEmail(user) {
        const self = this;

        let displayEmail = '';
        if (!user) {
            user = self.getUser();
        }

        if (user) {
            displayEmail = user.email;
        }

        return displayEmail;
    }

    getStatusClass(status) {
        if (status == 1) {
            return 'text-success';
        } else if (status == null || status == '') {
            return 'text-info';
        } else if (status == 0 || status == 2) {
            return 'text-warning';
        }
    }

    getStatusText(status) {
        if(status == 1) {
            return 'Active';
        } else if(status == null || status == '') {
            return 'Pending';
        } else if(status == 0 || status == 2) {
            return 'Inactivated';
        }
    }

    // Roles

    isSuper() {
        const self = this;

        return self.hasRole('SUPER');
    }

    isAdmin() {
        const self = this;

        return self.hasRole('ADMIN');
    }

    isDeveloper() {
        const self = this;

        return self.hasRole('DEV');
    }

    isAgency() {
        const self = this;

        return self.hasRole('AGENCY') && !self.hasRole('AGENCY_BROKER');
    }

    isLawfirm() {
        const self = this;

        return self.hasRole('LAW_FIRM');
    }

    isLawyer() {
        const self = this;

        return self.hasRole('LAWYER');
    }

    isBroker() {
        const self = this;

        return self.hasRole('AGENCY_BROKER');
    }

    isCustomer() {
        const self = this;

        return self.hasRole('CUSTOMER');
    }

    isAMP() {
        const self = this;

        return self.hasRole('AMP_ADM');
    }

    getHomeUrl() {
        const self = this;

        let url = '/';

        if (!self.getUser())
            return url;

        if (self.isSuper()) {
            url = '/super';
        } else if (self.isAdmin()) {
            url = '/admin';
        } else if (self.isDeveloper()) {
            url = '/developer';
        } else if (self.isAgency()) {
            url = '/agency';
        } else if (self.isLawfirm()) {
            url = '/lawfirm';
        } else if (self.isLawyer()) {
            url = '/lawyer';
        } else if (self.isBroker()) {
            url = '/broker';
        } else if (self.isAMP()) {
            url = '/amp';
        } else if (self.isCustomer()) {
            url = '/folio';
        }

        return url;
    }
    // User

    // Password Change -------------------------------------------------------------------------------------------------

    showChangePasswordModal() {
        const self = this;

        let user = self.getUser();
        if (!user) return;

        self.changePasswordParams = {}
        self.changePasswordErrors = {}

        self.hideLoginModal();

        self.changePasswordModal = true;
        $('#changePasswordModal').modal('show');
    }

    hideChangePasswordModal() {
        const self = this;

        self.changePasswordModal = false;
        $('#changePasswordModal').modal('hide');
    }

    changePassword() {
        const self = this;

        if (self.changePasswordParams.user_password != self.changePasswordParams.user_password_confirmation) {
            self.changePasswordParams.user_password_confirmation = '';
            self.changePasswordErrors.user_password_confirmation = "Password does not match";
            return;
        }
        if (!self.SSUtilService.validateForm(self, 'ctrl.SSUserService', jQuery('#changePasswordModal')))
            return;

        self.changePasswordErrors = {}

        if(self.isDeveloper()){
            if(self.password_validation){
                if (!self.checkPasswordPolicyHere(self.changePasswordParams.user_password)) {
                    return;
                }
            }else{
                if (!self.checkPasswordPolicy(self.changePasswordParams.user_password)) {
                    self.changePasswordErrors.user_password = 'Your password must be at least 8 alphanumeric characters with at least one lowercase letter, one uppercase letter, a number, a symbol and not more than 14 alphanumeric characters.';
                    return;
                }
            }
        }

        loading(true);
        self.SSHttpService.putAPIRequest('sec/me/password', self.changePasswordParams).then(
            function (response) {
                loading(false);

                if (response instanceof Error) {
                    self.SSAlertService.parseAndDisplayError(response);

                    jQuery.each(response.data, function (key, value) {
                        try {
                            self.changePasswordErrors[key] = value[0];
                        } catch (ex) {
                        }
                    });

                    return;
                }

                self.SSAlertService.success('Password changed successfully.');
                self.hideChangePasswordModal();
            }
        );
    }

    // Password Change -------------------------------------------------------------------------------------------------

    // Phone Change -------------------------------------------------------------------------------------------------

    showChangePhoneModal() {
        const self = this;

        let user = self.getUser();
        if (!user) return;

        self.changePhoneParams = {};
        self.changePhoneErrors = {};

        self.hideLoginModal();
        self.maskPhoneNumber(self, 'ctrl.SSUserService.', 'frmChangePhone');
        self.changePhoneModal = true;
        $('#changePhoneModal').modal('show');
    }

    hideChangePhoneModal() {
        const self = this;

        self.changePhoneModal = false;
        $('#changePhoneModal').modal('hide');
    }

    changePhone() {
        const self = this;

        if (self.changePhoneParams.phone1 == "") {
            self.changePasswordErrors.phone1 = 'Phone number is required';
            return;
        }

        loading(true);
        self.SSHttpService.postAPIRequest('sec/me/update-phone', self.changePhoneParams).then(
            function (response) {
                loading(false);

                if (response instanceof Error) {
                    self.SSAlertService.parseAndDisplayError(response);

                    jQuery.each(response.data, function (key, value) {
                        try {
                            self.changePasswordErrors[key] = value[0];
                        } catch (ex) {
                        }
                    });

                    return;
                }

                self.SSAlertService.success('Email sent please follow the instructions to changed phone.');
                self.hideChangePhoneModal();
            }
        );
    }

    showLoginModal() {
        const self = this;

        try {
            //Add to Firebase Analytics Event
            firebase.analytics().logEvent('HM_PG_LOGIN_USER', {'event_category': 'Homepage'});
        }catch (e) {
            console.log("Error in firebase analytics" + e);
        }

        if (!self.loginModal || !$('#loginModal').is(":visible")) {
            self.hideLoginModal();
            self.loginModal = true;
            $('#loginModal').modal('show');
        }
    }

    showAgreementModal() {
        const self = this;
        if (!self.agreementModal || !$('#agreementModal').is(":visible")) {
            //self.hideLoginModal();
            self.agreementModal = true;
            $('#agreementModal').modal('show');
        }
    }

    show2FALoginModal(prefix_2fa) {
        const self = this;

        self.prefix_2fa = prefix_2fa;
        self.hideLoginModal();
        self.login2FAModal = true;
        $('#login2FAModal').modal('show');
    }

    showRegisterModal(asAgent, _registerParams) {
        const self = this;

        try {
            //Add to Firebase Analytics Event
            firebase.analytics().logEvent('HM_PG_SIGN_UP_USER', {'event_category': 'Homepage'});
        }catch (e) {
            console.log("Error in firebase analytics" + e);
        }

        if (asAgent) {
            self.registerParams = _registerParams;
            self.showRegisterAgentModal(true);
            return;
        }

        self.hideLoginModal();

        self.registerParams = {
            _as_agent: asAgent
        };
        self.registerErrors = {};

        self.maskPhoneNumber(self, 'ctrl.SSUserService.');

        self.registerModal = true;
        $('#registerModal').modal('show');
    }

    showRegisterBuyerModal() {
        const self = this;

        self.hideLoginModal();

        self.registerParams = {
            _as_agent: false
        };
        self.registerErrors = {};

        self.maskPhoneNumber(self, 'ctrl.SSUserService.');

        self.registerBuyerModal = true;
        $('#registerBuyerModal').modal('show');
    }

    showRegisterAgentModal(hasRegisterParams) {
        const self = this;

        self.hideLoginModal();

        if (!hasRegisterParams) {
            self.registerParams = {
                _as_agent: true
            };
        }
        
        self.registerErrors = {};

        self.maskPhoneNumber(self, 'ctrl.SSUserService.');

        self.registerAgentModal = true;
        $('#registerAgentModal').modal('show');
    }

    showForgetPasswordModal() {
        const self = this;
        self.hideLoginModal();
        self.forgetPasswordModal = true;
        self.maskPhoneNumber(self, 'ctrl.SSUserService.', 'passwordChangeForm');
        $('#forgetPasswordModal').modal('show');
    }

    forgetPassword() {
        const self = this;

        // self.maskPhoneNumber(self, 'ctrl.');

        self.showCaptchaError = false;
        self.showEmailError = false;

        self.forgetPasswordErrors = {};

        if(!self.forgetPasswordParams.email){
            self.forgetPasswordErrors.showEmailError = true;
            return;
        }

        // if(!self.forgetPasswordParams.phone1){
        //     self.forgetPasswordErrors.phone1 = true;
        //     return;
        // }

        let googleResponse = $('#forgetPasswordCaptcha').val();
        if (!googleResponse) {
            self.forgetPasswordErrors.showCaptchaError = true;
            return;
        }

        let params = {
            'user_email': self.forgetPasswordParams.email,
            'user_phone1': self.forgetPasswordParams.phone1
        };

        self.forgetPasswordInProcess = true;
        self.forgetPasswordError = null;
        self.forgetPasswordSuccess = null;

        self.SSHttpService.postAPIRequest('forget-password', params).then(
            function (responseObj) {
                self.forgetPasswordInProcess = false;

                if (responseObj instanceof Error) {
                    self.forgetPasswordError = responseObj.message;
                    return;
                }

                self.forgetPasswordSuccess = responseObj.message;

            });
    }

    showResetPasswordModal() {
        const self = this;
        self.hideLoginModal();
        self.resetPasswordModal = true;
        $('#resetPasswordModal').modal('show');
    }

    showThanksForRegisterModal(asAgent) {
        const self = this;

        self.hideLoginModal();

        self._as_agent = asAgent;

        self.thanksForRegisterModal = true;
        $('#thanksForRegisterModal').modal('show');
    }

    showActivationModal() {
        const self = this;
        self.hideLoginModal();
        self.activationModal = true;
        $('#activationModal').modal('show');
    }

    showEnquireModal(property, unit = null) {
        const self = this;
        let unit_id = null;
        self.hideLoginModal();

        self.property = property;
        self.unit = unit;

        // Register
        self.registerParams = {
            _as_agent: false,
            _from_enquiry: true,
        };
        self.registerErrors = {};
        if (unit)
            unit_id = self.unit.id;
        // Enquiry
        self.enquireParams = {
            property_id: self.property.id,
            unit_id: unit_id,
            register_from_share_code: self.SSUtilService.getURLParameter('share_code'),
            txt_message: 'I am interested to know more about this development.\nPlease call or message me.\n\nThank you.'
        };
        self.enquireErrors = {};

        self.maskPhoneNumber(self, 'ctrl.SSUserService.');

        self.enquireModal = true;
        $('#enquireModal').modal('show');
    }

    sendEnquiry() {
        const self = this;

        if (self.validateEnquireParams()) return;

        if (self.getUser()) {
            self._sendEnquiry();
        } else {
            self.registerUser();
        }
    }

    _sendEnquiry() {
        const self = this;

        loading(true);
        self.SSHttpService.postAPIRequest('enquiry/create', self.enquireParams).then(
            function (responseObj) {
                loading(false);
                if (responseObj instanceof Error) {
                    console.log('Error on inserting buyer enquiries into db');
                    return;
                }

                if (responseObj.data.enquiry) {
                    self.enquiryEmailVerificationPending = responseObj.data.enquiry.status == 0;
                }
                self.showEnquirySuccessModal();
            }
        );
    }

    registerUser() {
        const self = this;

        if (self.validateRegisterParams()) return;

        let params = self.registerParams;

        var url = 'register';
        if (params._as_agent) {
            url = 'register-broker';
        }

        self.registerInProcess = true;
        self.registerError = null;

        loading(true);
        self.SSHttpService.postAPIRequest(url, params).then(
            function (responseObj) {
                loading(false);
                self.registerInProcess = false;

                if (responseObj instanceof Error) {

                    self.registerError = responseObj.serverMessage;
                    switch (responseObj.code) {
                        case 412:
                            self.registerError = 'Incorrect Information.';
                            break;
                        default:
                            self.registerError = 'Oops, Something went wrong. Please contact Showsuite support.';
                            break;
                    }

                    // Form Errors
                    self.enquireErrors = {};
                    self.registerErrors = {};

                    jQuery.each(responseObj.data, function (key, value) {
                        try {
                            self.enquireErrors[key] = value[0];
                        } catch (ex) {
                        }
                        try {
                            self.registerErrors[key] = value[0];
                        } catch (ex) {
                        }
                    });
                    // Form Errors

                    return;
                }

                // Refresh developer's 'roles/permissions'
                self.updateRolePermissions();

                if (params._from_enquiry) {
                    self._sendEnquiry();
                } else {
                    self.showThanksForRegisterModal(params._as_agent);
                }
            }
        );
    }

    validateRegisterParams() {
        const self = this;

        // Return if user is already logged-in
        if (self.getUser()) return;

        let hasError = false;
        self.registerErrors = {};

        if (!self.registerParams.user_first_name) {
            self.registerErrors.user_first_name = "The First Name field is required.";
            hasError = true;
        }
        if (!self.registerParams.user_last_name) {
            self.registerErrors.user_last_name = "The Last Name field is required.";
            hasError = true;
        }
        if (!self.registerParams.user_email) {
            self.registerErrors.user_email = "The Email field is required.";
            hasError = true;
        }

        if (self.registerParams._as_agent) {
            if (!self.registerParams.user_phone1) {
                self.registerErrors.user_phone1 = "The Phone field is required.";
                hasError = true;
            }
            //if (!self.registerParams.broker_nric) {
            //    self.registerErrors.broker_nric = "The NRIC/FIN field is required.";
            //    hasError = true;
            //} else
            if (self.registerParams.broker_nric && !validateNRIC(self.registerParams.broker_nric)) {
                self.registerErrors.broker_nric = "The NRIC/FIN format is wrong.";
                hasError = true;
            }

            if (!self.registerParams.broker_agency_id) {
                self.registerErrors.broker_agency_id = "The Agency field is required.";
                hasError = true;
            }

            if (!self.registerParams.broker_is_support_staff) {
                if (!self.registerParams.broker_reg_no) {
                    self.registerErrors.broker_reg_no = "The Registration No. field is required.";
                    hasError = true;
                }
            }
        }

        if (jQuery('.intl-tel-input').hasClass('has-error') && jQuery("[allowdropdown='']").val() != '') {
            self.registerErrors.user_phone1 = "The Phone format is not valid.";
            hasError = true;
        }

        return hasError;
    }

    validateEnquireParams() {
        const self = this;

        let hasEnquireError = false;
        self.enquireErrors = {};

        if (!self.enquireParams.txt_message) {
            self.enquireErrors.txt_message = "The enquiry message is required.";
            hasEnquireError = true;
        }
        
        let hasRegisterError = self.validateRegisterParams();
        
        return hasEnquireError || hasRegisterError;
    }

    showEnquirySuccessModal() {
        const self = this;

        self.hideLoginModal();

        self.enquirySuccessModal = true;
        $('#enquirySuccessModal').modal('show');
    }

    showErrorPopupModal() {
        const self = this;

        self.hideLoginModal();

        self.errorPopupModal = true;
        $('#errorPopupModal').modal('show');        
    }

    showFeedbackModal(type) {
        const self = this;

        self.hideLoginModal();

        self.feedbackParams = {};
        if (type) {
            self.feedbackParams.type = type;
        }
        self.feedbackErrors = {};

        self.maskPhoneNumber(self, 'ctrl.SSUserService.', 'feedbackModal');

        self.feedbackModal = true;
        $('#feedbackModal').modal('show');
    }

    showExpiryPasswordModal() {
        const self = this;

        if (!self.show_expiry_password_modal || !$('#expiryPasswordModal').is(":visible")) {
            self.hideLoginModal();
            self.show_expiry_password_modal = true;
            $('#expiryPasswordModal').modal('show');
        }
    }

    maskPhoneNumber(context, contextPrefix, formId) {
        let self = this;

        let parent = jQuery;
        if (formId) {
            parent = jQuery('#' + formId);
        }

        jQuery.each(parent.find('input[type=tel]'), function (i, el) {
            let field = jQuery(el);

            let allowDropdown = field.attr('allowDropdown');
            allowDropdown = !allowDropdown || allowDropdown !== 'false';

            field.intlTelInput({
                initialCountry: 'sg',
                geoIpLookup: self.SSUtilService.geoIpLookup,
                allowDropdown: allowDropdown,
                preferredCountries: ['sg']
            });

            let model = field.attr('tel-ng-model');
            
            if(!model){
                model = field.attr('ng-model');
            }
            if (model) {
                if (startsWith(model, contextPrefix)) {
                    model = model.substring(contextPrefix.length);
                }
                let value = self.$parse(model)(context);
                if (value) {
                    field.intlTelInput('setNumber', value);
                }
            }


            field.on("keyup change", function () {
                field.parent().removeClass("has-error");
                field.attr('placeholder', 'Enter contact number');
            });

            field.blur(function () {
                var currentFormat = (field.val() && field.val()[0] === "+") ? intlTelInputUtils.numberFormat.INTERNATIONAL : intlTelInputUtils.numberFormat.NATIONAL;
                field.val(field.intlTelInput("getNumber", currentFormat));
                field.parent().removeClass("has-error");
                if (!field.intlTelInput("isValidNumber")) {
                    field.parent().addClass("has-error");
                    if (model) {
                        //self.$parse(model).assign(context, ""); // Don't remove the number if invalid, unless we upgrade to version of latest intl-tel-input
                    }
                } else {
                    if (model) {
                        self.$parse(model).assign(context, field.intlTelInput("getNumber"));
                    }
                }
                field.attr('placeholder', 'Enter contact number');
            });
        });
    }

    hideLoginModal() {
        const self = this;

        self.loginModal = false;
        self.login2FAModal = false;
        self.registerModal = false;
        self.registerBuyerModal = false;
        self.registerAgentModal = false;
        self.forgetPasswordModal = false;
        self.resetPasswordModal = false;
        self.activationModal = false;
        self.thanksForRegisterModal = false;
        self.enquireModal = false;
        self.enquirySuccessModal = false;
        self.errorPopupModal = false;        
        self.feedbackModal = false;
        self.auth2FAModal = false;
        self.auth2FALaunchModal = false;
        self.abortConfirmationModal = false;
        self.familySuccessfulModal = false;
        self.show_expiry_password_modal = false;
        self.agreementModal = false;

        if ($('#loginModal').is(':visible')) $('#loginModal').modal('hide');
        if ($('#login2FAModal').is(':visible')) $('#login2FAModal').modal('hide');
        if ($('#registerModal').is(':visible')) $('#registerModal').modal('hide');
        if ($('#registerBuyerModal').is(':visible')) $('#registerBuyerModal').modal('hide');
        if ($('#registerAgentModal').is(':visible')) $('#registerAgentModal').modal('hide');
        if ($('#forgetPasswordModal').is(':visible')) $('#forgetPasswordModal').modal('hide');
        if ($('#resetPasswordModal').is(':visible')) $('#resetPasswordModal').modal('hide');
        if ($('#activationModal').is(':visible')) $('#activationModal').modal('hide');
        if ($('#thanksForRegisterModal').is(':visible')) $('#thanksForRegisterModal').modal('hide');
        if ($('#enquireModal').is(':visible')) $('#enquireModal').modal('hide');
        if ($('#enquirySuccessModal').is(':visible')) $('#enquirySuccessModal').modal('hide');
        if ($('#errorPopupModal').is(':visible')) $('#errorPopupModal').modal('hide');
        if ($('#feedbackModal').is(':visible')) $('#feedbackModal').modal('hide');
        if ($('#auth2FAModal').is(':visible')) $('#auth2FAModal').modal('hide');
        if ($('#abortConfirmationModal').is(':visible')) $('#abortConfirmationModal').modal('hide');
        if ($('#familySuccessfulModal').is(':visible')) $('#familySuccessfulModal').modal('hide');
        if ($('#auth2FALaunchModal').is(':visible')) $('#auth2FALaunchModal').modal('hide');
        if ($('#expiryPasswordModal').is(':visible')) $('#expiryPasswordModal').modal('hide');
        if ($('#agreementModal').is(':visible')) $('#agreementModal').modal('hide');
    }

    /* Favourites */
    setUserFavouriteProperties(properties) {
        const self = this;
        self.userStorageObject.userFavouriteProperties = properties;
    }

    addUserFavouriteProperty(propertyId) {
        const self = this;
        if (!self.userStorageObject.userFavouriteProperties) {
            self.userStorageObject.userFavouriteProperties = [];
        }
        self.userStorageObject.userFavouriteProperties.push(propertyId)
    }

    deleteUserFavouriteProperty(propertyId) {
        const self = this;
        if (self.userStorageObject.userFavouriteProperties) {
            var index = self.userStorageObject.userFavouriteProperties.indexOf(propertyId);
            if (index > -1) {
                self.userStorageObject.userFavouriteProperties.splice(index, 1);
            }
        }
    }

    setUserFavouriteUnits(units) {
        const self = this;
        self.userStorageObject.userFavouriteUnits = units;
    }

    addUserFavouriteUnit(unitId) {
        const self = this;
        if (!self.userStorageObject.userFavouriteUnits) {
            self.userStorageObject.userFavouriteUnits = [];
        }
        self.userStorageObject.userFavouriteUnits.push(unitId)
    }

    deleteUserFavouriteUnit(unitId) {
        const self = this;
        if (self.userStorageObject.userFavouriteUnits) {
            var index = self.userStorageObject.userFavouriteUnits.indexOf(unitId);
            if (index > -1) {
                self.userStorageObject.userFavouriteUnits.splice(index, 1);
            }
        }
    }

    updatePropertyFavorite(propertyId) {
        const self = this;

        if (!self.getUser()) {
            self.pendingFavouriteProperty = propertyId;
            self.showLoginModal();
            return;
        }

        if (!propertyId || self.updatePropertyFavoriteInProcess) {
            return;
        }

        self.updatePropertyFavoriteInProcess = propertyId;
        if (!self.isPropertyFavourite(propertyId)) {
            self.SSHttpService.postAPIRequest('sec/favourites/properties/add?property_id=' + propertyId).then(
                function (response) {
                    self.updatePropertyFavoriteInProcess = null;
                    if (response instanceof Error) {
                        if (response.code == 401) {
                            self.clearUser();
                            self.showLoginModal();
                        }
                        return;
                    }
                    self.addUserFavouriteProperty(propertyId);
                });
        } else {
            self.SSHttpService.getAPIRequest('sec/favourites/properties/delete?property_id=' + propertyId).then(
                function (response) {
                    self.updatePropertyFavoriteInProcess = null;
                    if (response instanceof Error) {
                        if (response.code == 401) {
                            self.clearUser();
                            self.showLoginModal();
                        }
                        return;
                    }
                    self.deleteUserFavouriteProperty(propertyId);
                });
        }
    }

    isPropertyFavourite(propertyId) {
        const self = this;
        return self.getUser() && propertyId && self.userStorageObject.userFavouriteProperties &&
            self.userStorageObject.userFavouriteProperties.indexOf(propertyId) > -1;
    }

    updateUnitFavorite(unitId) {
        const self = this;

        if (!self.getUser()) {
            self.pendingFavouriteUnit = unitId;
            self.showLoginModal();
            return;
        }

        if (!unitId || self.favouriteUpdateInProcess) {
            return;
        }

        self.favouriteUpdateInProcess = "unit" + unitId;
        if (!self.isUnitFavourite(unitId)) {
            self.SSHttpService.postAPIRequest('sec/favourites/units/add?unit_id=' + unitId).then(
                function (response) {
                    self.favouriteUpdateInProcess = null;
                    if (response instanceof Error) {
                        if (response.code == 401) {
                            self.clearUser();
                            self.showLoginModal();
                        }
                        return;
                    }
                    self.addUserFavouriteUnit(unitId);
                });
        } else {
            self.SSHttpService.getAPIRequest('sec/favourites/units/delete?unit_id=' + unitId).then(
                function (response) {
                    self.favouriteUpdateInProcess = null;
                    if (response instanceof Error) {
                        if (response.code == 401) {
                            self.clearUser();
                            self.showLoginModal();
                        }
                        return;
                    }
                    self.deleteUserFavouriteUnit(unitId);
                });
        }
    }

    isUnitFavourite(unitId) {
        const self = this;
        return self.getUser() && unitId && self.userStorageObject.userFavouriteUnits &&
            self.userStorageObject.userFavouriteUnits.indexOf(unitId) > -1;
    }

    updateFavorites() {
        const self = this;

        if (!self.getUser()) {
            return;
        }

        self.userStorageObject.userFavouriteProperties = [];
        self.userStorageObject.userFavouriteUnits = [];

        self.SSHttpService.getAPIRequest('sec/favourites/get').then(
            function (response) {
                if (response instanceof Error) {
                    if (response.code == 401) {
                        self.clearUser();
                    }
                    return;
                }

                self.userStorageObject.userFavouriteProperties = response.data.properties;
                self.userStorageObject.userFavouriteUnits = response.data.property_units;

                if (!self.isPropertyFavourite(self.pendingFavouriteProperty)) {
                    self.updatePropertyFavorite(self.pendingFavouriteProperty);
                }
                self.pendingFavouriteProperty = null;

                if (!self.isUnitFavourite(self.pendingFavouriteUnit)) {
                    self.updatePropertyFavorite(self.pendingFavouriteUnit);
                }
                self.pendingFavouriteUnit = null;
            }
        );
    }

    checkPropertyLaunchAvailability(phaseAvailability, projectBrokerType) {
        const self = this;

        if (!(SHOWSUITE.AVAILABILITY_PUBLIC
            && SHOWSUITE.AVAILABILITY_BROKERS_ALL
            && SHOWSUITE.AVAILABILITY_BROKERS_TEAM
            && SHOWSUITE.AVAILABILITY_TEAM_LEADS
            && SHOWSUITE.AVAILABILITY_DEVELOPER_TEAM
        )) {
            // If missing, initialize
            SHOWSUITE.AVAILABILITY_PUBLIC = 10;
            SHOWSUITE.AVAILABILITY_BROKERS_ALL = 20;
            SHOWSUITE.AVAILABILITY_BROKERS_TEAM = 30;
            SHOWSUITE.AVAILABILITY_TEAM_LEADS = 40;
            SHOWSUITE.AVAILABILITY_DEVELOPER_TEAM = 50;
        }

        let available = false;

        if (phaseAvailability === SHOWSUITE.AVAILABILITY_PUBLIC) {
            available = true;
        } else if (phaseAvailability === SHOWSUITE.AVAILABILITY_BROKERS_ALL) {
            if (self.isBroker()) {
                available = true;
            }
        } else if (phaseAvailability === SHOWSUITE.AVAILABILITY_BROKERS_TEAM) {
            if (self.isBroker() && projectBrokerType) {
                available = true;
            }
        } else if (phaseAvailability === SHOWSUITE.AVAILABILITY_TEAM_LEADS) {
            if (self.isBroker()
                && (projectBrokerType === SHOWSUITE.PROJECT_BROKER_TYPE_ASSISTANT_TEAMLEAD
                    || projectBrokerType === SHOWSUITE.PROJECT_BROKER_TYPE_TEAMLEAD)
            ) {
                available = true;
            }
        } else if (phaseAvailability === SHOWSUITE.AVAILABILITY_DEVELOPER_TEAM) {
            let user = self.getUser();
            if (self.isBroker() && projectBrokerType && user && user.is_developer_team) {
                available = true;
            }
        }

        return available;
    }

    getActivePropertyLaunchForEoiCreate(property, phase=null) {
        const self = this;

        if (!property
            || !property.property_launches
            || property.property_launches.length === 0
            || !self.getUser()
        ) {
            return;
        }

        let propertyLaunch = null;
        $.each(property.property_launches, function (i, _propertyLaunch) {
            if (self.checkPropertyIsValidForEoiCreate(property, _propertyLaunch, phase)) {
                propertyLaunch = _propertyLaunch;
                return false; // break
            }
        });

        return propertyLaunch;
    }

    checkPropertyIsValidForEoiCreate(property, propertyLaunchOrId, phase=null) {
        const self = this;

        if (!property) {
            return false;
        }

        //---------------------------------------
        let propertyLaunch = null;

        // If passed value is propertyLaunchId then try to search propertyLaunch from the property
        if ((typeof propertyLaunchOrId === 'number' || typeof propertyLaunchOrId === 'string')
            && property.property_launches
        ) {
            let propertyLaunchId = propertyLaunchOrId;
            $.each(property.property_launches, function (i, _propertyLaunch) {
                if (_propertyLaunch.id === propertyLaunchId) {
                    propertyLaunch = _propertyLaunch;
                    return false; // break
                }
            });
        } else {
            propertyLaunch = propertyLaunchOrId;
        }

        if (!propertyLaunch
            || typeof propertyLaunch !== 'object'
        ) {
            return false;
        }
        //---------------------------------------


        let currentPhase = self.getCurrentPhaseOfPropertyLaunch(propertyLaunch);
        currentPhase = !phase || phase === currentPhase ? currentPhase : null; // Check if caller is asking for a specific phase validation

        let propertyLaunchAvailability = null;
        if (currentPhase === 'EOI') {
            propertyLaunchAvailability = propertyLaunch.availability;
        } else if (currentPhase === 'Launchday' || currentPhase === 'PostLaunch') {
            propertyLaunchAvailability = propertyLaunch.post_launch_availability;
        }

        return self.checkPropertyLaunchAvailability(propertyLaunchAvailability, property.project_broker_type);
    }

    getBrokerAgencyWithProperty() {
        const self = this;

        self.user = null;

        let url = 'sec/agents/getAgentProperties';

        return self.SSHttpService.getAPIRequest(url);
    }

    linkToEoi(propertyId, propertyLaunchId, redirect) {
        const self = this;

        self.viewEoi(propertyId, propertyLaunchId, redirect);
    }

    show2FAAuthModal(response) {
        const self = this;
        self.hideLoginModal();

        let user = self.getUser();

        if (!user)
            return;
        self.hidePhoneNumber(user);

        if(response && response.data && response.data.prefix_2fa){
            self.prefix_2fa = response.data.prefix_2fa;
        }

        self.auth2FAModal = true;
        $('#auth2FAModal').modal('show');
    }

    show2FALaunchAuthModal(response) {
        const self = this;
        self.hideLoginModal();

        let user = self.getUser();

        if (!user)
            return;
        self.hidePhoneNumber(user);

        if(response && response.data && response.data.prefix_2fa){
            self.prefix_2fa = response.data.prefix_2fa;
        }

        self.auth2FALaunchModal = true;
        $('#auth2FALaunchModal').modal('show');
    }

    hidePhoneNumber(user) {
        const self = this;

        self.masked_phone_number = "XXXXXXXX";

        if (user.phone1) {
            self.masked_phone_number = self.hideFrontPhoneNumber(user.phone1);
        } else if (user.phone2) {
            self.masked_phone_number = self.hideFrontPhoneNumber(user.phone2);
        }
    }

    hideFrontPhoneNumber(phone_number) {
        return phone_number.replace(/.(?=.{4,}$)/g, 'X');;
    }

    check2FA() {
        const self = this;

        self.auth_2fa_error = ""

        let user = self.getUser();
        if (!user)
            return;

        let params = {
            email: user.email,
            twofa_code: self.verification_code,
            twofa_type: self.TYPE_SESSION_2FA
        };

        loading(true);
        self.SSHttpService.postAPIRequest('2fa', params).then(function (response) {
            loading(false);
            
            if (response instanceof Error) {
                self.auth_2fa_error = "The code is invalid, please try again later.";
            } else {
                // Redirect to Eoi Page
                self.hideLoginModal();
                self.viewEoi(self.propertyId);
            }
        });
    }

    viewEoi(propertyId, propertyLaunchId, redirect) {
        const self = this;

        self.eoi_property_launch_id = propertyLaunchId;

        let url = '';

        if (propertyLaunchId) {
            url = '/' + propertyId + '/' + propertyLaunchId + '/eoi';
        } else {
            url = '/' + propertyId + '/eoi';
        }

        if(redirect)
            url = url + '?redirect='+redirect;

        window.location.href = url;
    }

    showAbortConfirmationModal() {
        const self = this;
        self.hideLoginModal();

        let user = self.getUser();

        if (!user)
            return;

        self.abortConfirmationModal = true;
        $('#abortConfirmationModal').modal('show');
    }

    abortEoi(property_id, property_launch) {
        const self = this;

        self.hideLoginModal();

        let url = '/broker/sale/';

        if(property_launch){
            url = url + property_id + '/launches/' + property_launch.id;
        }

        let redirect = self.SSUtilService.getURLParameter('redirect');
        url = url +'/'+ (redirect? redirect : 'eoi');

        window.location.href = url;
    }

    showFamilySuccessfulModal() {
        const self = this;
        self.hideLoginModal();

        self.familySuccessfulModal = true;
        $('#familySuccessfulModal').modal('show');
    }

    getActivePropertyLaunch(property, propertyLaunchId=null, phase=null) {
        const self = this;

        let propertyLaunch = null;

        if (property && property.property_launches) {
            $.each(property.property_launches, function (i, _propertyLaunch) {
                if (propertyLaunchId) {
                    // Find the Property Launch for the given id
                    if (_propertyLaunch.id === propertyLaunchId) {
                        propertyLaunch = _propertyLaunch;
                        return false; // break
                    }
                } else {
                    // Find the Property Launch with active phase as provided
                    let currentPhase = self.getCurrentPhaseOfPropertyLaunch(_propertyLaunch);
                    if (currentPhase === phase) {
                        propertyLaunch = _propertyLaunch;
                        return false; // break
                    }
                }
            });
        }

        return propertyLaunch;
    }

    getCurrentPhaseOfPropertyLaunch(propertyLaunch) {
        const self = this;

        if (!propertyLaunch) {
            return null;
        }

        let eoiStartDate = moment(propertyLaunch.eoi_start_date);
        let eoiEndDate = moment(propertyLaunch.eoi_end_date);
        let launchStartDate = moment(propertyLaunch.launch_start_date);
        let launchEndDate = moment(propertyLaunch.launch_end_date);
        let todayDate = moment(moment().utc().format('YYYY-MM-DD HH:mm:ss')); // Use UTC date to compare all dates in same timezone.

        if (todayDate.isBefore(eoiStartDate)) {
            return "Upcoming";

        } else if (todayDate.isSameOrAfter(eoiStartDate)
            && todayDate.isSameOrBefore(eoiEndDate)
        ) {
            return "EOI";

        } else if (todayDate.isAfter(eoiEndDate)
            && todayDate.isBefore(launchStartDate)
        ) {
            return "Cooldown";

        } else if (todayDate.isSameOrAfter(launchStartDate)
            && todayDate.isSameOrBefore(launchEndDate)
        ) {
            return "Launchday";

        } else if (todayDate.isAfter(launchEndDate)) {
            return "PostLaunch";
        }
    }

    addSkipPassword() {
        const self = this;

        self.$cookies.put('skip_password', true);
    }

    getSkipPassword() {
        const self = this;

        return self.$cookies.get('skip_password');
    }

    checkPasswordPolicyHere(password) {
        const self = this;
        self.changePasswordErrors.user_password = "";
        if(self.password_validation.min_length && password.length < self.password_validation.min_length){
            self.changePasswordErrors.user_password = "Your password must be at least " + self.password_validation.min_length + " alphanumeric characters";
        }
        if(self.password_validation.require_lowercase && !(/[a-z]/.test(password))){
            if(self.changePasswordErrors.user_password != ""){
                self.changePasswordErrors.user_password += " with atleast one lowercase letter";
            }
            else{
                self.changePasswordErrors.user_password = "Your password must contain atleast one lowercase letter";
            }
        }
        if(self.password_validation.require_uppercase && !(/[A-Z]/.test(password))){
            if(self.changePasswordErrors.user_password != ""){
                if(self.password_validation.require_number && !(/\d/.test(password))){
                    self.changePasswordErrors.user_password += ",";
                }
                else if(self.password_validation.require_symbol && !(/[!@#$%^&*]/.test(password))){
                    self.changePasswordErrors.user_password += ",";
                }
                else if(self.password_validation.max_length && password.length > self.password_validation.max_length){
                    self.changePasswordErrors.user_password += ",";
                }else{
                    self.changePasswordErrors.user_password += " and";
                }
                self.changePasswordErrors.user_password += " one uppercase letter";
            }
            else{
                self.changePasswordErrors.user_password = "Your password must contain atleast one uppercase letter";
            }
        }
        if(self.password_validation.require_number && !(/\d/.test(password))){
            if(self.changePasswordErrors.user_password != ""){
                if(self.password_validation.require_symbol && !(/[!@#$%^&*]/.test(password))){
                    self.changePasswordErrors.user_password += ",";
                }
                else if(self.password_validation.max_length && password.length > self.password_validation.max_length){
                    self.changePasswordErrors.user_password += ",";
                }else{
                    self.changePasswordErrors.user_password += " and";
                }
                self.changePasswordErrors.user_password += " a number";
            }
            else{
                self.changePasswordErrors.user_password = "Your password must contain atleast one number";
            }
        }
        if(self.password_validation.require_symbol && !(/[!@#$%^&*]/.test(password))){
            if(self.changePasswordErrors.user_password != ""){
                if(self.password_validation.max_length && password.length > self.password_validation.max_length){
                    self.changePasswordErrors.user_password += ",";
                }else{

                    self.changePasswordErrors.user_password += " and";
                }
                self.changePasswordErrors.user_password += " a symbol";
            }
            else{
                self.changePasswordErrors.user_password = "Your password must contain atleast one symbol";
            }
        }
        if(self.password_validation.max_length && password.length > self.password_validation.max_length){
            if(self.changePasswordErrors.user_password != ""){
                self.changePasswordErrors.user_password += " and not more than " + self.password_validation.max_length + "alphanumeric characters";
            }
            else{
                self.changePasswordErrors.user_password = "Your password must be less than " + self.password_validation.max_length + " alphanumeric characters";
            }
        }
        if(self.changePasswordErrors.user_password != ""){
            return false;
        }
        return true;
    }

    checkPasswordPolicy(password) {
        const self = this;

        var patt = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()+-<>/':;"=`])[A-Za-z\d@$!%*?&#^()+-<>/':;"=`]{8,14}$/;
        return patt.test(password);
    }

    requestNotificationPermission(FIREBASE_VAPID_KEY){
        const self = this;
        self.FIREBASE_VAPID_KEY = FIREBASE_VAPID_KEY;
        self.SSFirebaseMessagingService.requestNotificationPermission();
    }
}

SSUserService.$inject = ['$rootScope', '$window', '$parse', '$timeout', '$cookies', 'SSUtilService', 'SSHttpService', 'SSConfirmationService', 'SSAlertService', 'SSFirebaseMessagingService'];
app.service('SSUserService', SSUserService);
